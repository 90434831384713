import type { Notification } from "@nuxt/ui/dist/runtime/types";
import IconSent from "~/assets/icons/notification-sent.svg";
import IconCheckmark from "~/assets/icons/notification-checkmark.svg";
import IconCross from "~/assets/icons/notification-cross.svg";

export type NotificationType = "sent" | "success" | "error";

export const useNotification = () => {
  const toast = useToast();

  const backgroundStyles: Record<NotificationType, string> = {
    sent: "bg-green-400 text-black",
    success: "bg-green-400 text-black",
    error: "bg-red-400 text-black",
  };

  const icons: Record<NotificationType, string> = {
    sent: IconSent,
    success: IconCheckmark,
    error: IconCross,
  };

  const getTitle = (title: string, type: NotificationType): string => {
    const iconSrc = icons[type];

    if (iconSrc) return `<img src="${iconSrc}" alt="icon-${type}" />${title}`;
    return title;
  };

  const toastClick = (notification: Notification) => {
    toast.remove(notification.id);
  };

  const baseToastOptions: Partial<Notification> = {
    timeout: 5000,
    click: toastClick,
    closeButton: { icon: "" },
  };

  const add = (type: NotificationType, title: string, description?: string) => {
    toast.add({
      ...baseToastOptions,
      title: getTitle(title, type),
      description,
      ui: {
        background: backgroundStyles[type],
      },
    });
  };

  return { add };
};
